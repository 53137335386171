window.addEventListener('load', () => {
  const toggleOverlay = () => {
    const overlay = document.querySelector('div[data-casual-user-form-overlay]')

    overlay.style.display = overlay.style.display === 'block' ? 'none' : 'block'
  }

  const renderSuccessMsg = () => {
    const formArea = document.querySelector('div[data-casual-user-form-area]')
    const completeArea = document.querySelector('div[data-casual-user-complete-area]')
    formArea.style.display = 'none'
    completeArea.style.display = 'block'
  }

  const renderLoginMsg = () => {
    const formArea = document.querySelector('div[data-casual-user-form-area]')
    const loginArea = document.querySelector('div[data-casual-user-login-area]')
    formArea.style.display = 'none'
    loginArea.style.display = 'block'
  }

  const responseHandler = (response) => {
    if(response.status === 'success') {
      renderSuccessMsg()
    } else if (response.status === 'error') {
      if(response.msg.includes("メールアドレスはm3.com会員に登録済みです")){
        renderLoginMsg()
      } else {
        const errorMsgArea = document.querySelector('div[data-casual-user-error-msg-area]')
        errorMsgArea.innerHTML = ''
        response.msg.forEach((error) => {
          errorMsgArea.innerHTML += `<span class="casual-user__modal-error">${error}</span><br>`
        })
      }
    }
    toggleOverlay()
  }

  const registerForm = document.querySelector('form[data-casual-user-register]')
  const casualUserOptinCheck = document.querySelector('input[data-casual-user-optin-check]')

  if(!!!registerForm || !!!casualUserOptinCheck) { return };

  casualUserOptinCheck.addEventListener('change', (e) => {
    const submitBtn = document.querySelector('input[data-casual-user-submit-btn]')
    submitBtn.disabled = !e.target.checked
  })

  const overlay = document.querySelector('div[data-casual-user-form-overlay]')
  registerForm.addEventListener('submit', (e) => {
    e.preventDefault()
    toggleOverlay()
    const formData = new FormData(registerForm)

    fetch(registerForm.action, {
      method: 'POST',
      body: formData,
      cache: 'no-store'
    }).then((response) => {
      if(!response.ok) {
        alert("現在、サービスがご利用できない、またはご利用しづらい状態となっております。ご迷惑をおかけしますが時間をおいて再度お試しください。")
      }
      response.json().then((res) => responseHandler(res))
    })
    .catch((error) => {
      alert("現在、サービスがご利用できない、またはご利用しづらい状態となっております。ご迷惑をおかけしますが時間をおいて再度お試しください。")
    })
  })
})
