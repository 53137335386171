// HACK: 記事の目次に使用している(=to-accordionをgrepしても出てこない)
//       アコーディオンする要素をボタンのnextElementSiblingで指定しているためあまり柔軟性がない。
//       他にアコーディオンを使いたい場合はaccordion.jsを使用する
document.addEventListener('DOMContentLoaded', () => {
  // toc = table of contents
  // 参考 https://www.w3schools.com/howto/howto_js_accordion.asp
  const tocAccordions = document.getElementsByClassName('toc-accordion')

  Array.from(tocAccordions).forEach((acc) => {
    acc.addEventListener('click', function toggleAccordion() {
      this.classList.toggle('active')
      const panel = this.nextElementSibling
      if (panel.style.display === 'none') {
        panel.style.display = 'block'
      } else {
        panel.style.display = 'none'
      }
      if (this.innerHTML === '表示') {
        this.innerHTML = '非表示'
      } else if (this.innerHTML === '非表示') {
        this.innerHTML = '表示'
      }
    })
  })
})
