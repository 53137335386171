// TODO: PHARMACIST-10174のABテストで使用している。
//       テスト終了後は不要なら削除する。
// NOTE: pagetopボタンの挙動は"s.m3img.com/atlas/1.0.0/javascripts/atlas.global.min.js"に定義されていて、scroll位置に応じてにdisplay: block/noneを切り替えている。
//       そのためバナー表示時にpagetopボタンを非表示にするという要件に対してdisplayプロパティで制御ができないという制約がある
//       解決策としてpagetopボタン非表示時には要素をremoveしておき、バナー表示時には改めてpagetopボタンをcreateElementして表示にするようにしている
window.addEventListener('load', () => {
  const bannerElem = document.querySelector('[data-article-footer-banner-display]')
  if (!bannerElem) { return }

  const articleFooterBannerSession = sessionStorage.getItem('articleFooterBanner')
  if (!!articleFooterBannerSession) { return }

  const isMobile =  !!document.querySelector('body.is-mobile')

  const buildPageTopBtn = () => {
    const elem = document.createElement('p')
    elem.id = "m3sp-pagetop"
    elem.className = "m3sp-pagetop"

    return elem
  }

  const buildPagetopBtnAnchor = () => {
    const elem = document.createElement('a')
    elem.href = "#js-sp-container"

    return elem
  }

  const buildPagetopBtnIcon = () => {
    const elem = document.createElement('i')
    elem.className = "atlas-glyphs-arrow-up"

    return elem
  }

  const buildPagetopBtnElems = () => {
    const pagetopBtn = buildPageTopBtn()
    const pagetopBtnAnchor = buildPagetopBtnAnchor()
    const pagetopBtnIcon = buildPagetopBtnIcon()

    pagetopBtnAnchor.appendChild(pagetopBtnIcon)
    pagetopBtn.appendChild(pagetopBtnAnchor)

    return pagetopBtn
  }

  const initNewPagetopBtn = () => {
    const newPagetopBtnElem = buildPagetopBtnElems()
    const pagetopReplaceMarker = document.querySelector('[data-pagetop-replace-marker]')
    pagetopReplaceMarker.after(newPagetopBtnElem)
    pagetopReplaceMarker.remove()

    // NOTE: 本来はjQueryを剥がしたいが、エムスリー共通で使っているスクリプトと合わせるためにjQueryを使っている
    const pagetop = $(newPagetopBtnElem);
    $(window).scroll(function () {
      if ($(this).scrollTop() > 400) {
        pagetop.fadeIn();
      } else {
        pagetop.fadeOut();
      }
    });
    pagetop.click(function () {
      $('body, html').animate({ scrollTop: 0 }, 500);
      return false;
    });
  }

  const replaceDefaultPageTopBtn = () => {
    const pagetopBtn = document.querySelector('.m3sp-pagetop[data-pagetop-scroll-btn]')
    const pagetopReplaceMarker = document.createElement('p')
    pagetopReplaceMarker.setAttribute('data-pagetop-replace-marker', true)
    pagetopReplaceMarker.setAttribute('style', 'display: none;')
    pagetopBtn.after(pagetopReplaceMarker)
    pagetopBtn.remove()
  }

  const bannerCloseBtn = document.querySelector('[data-article-footer-banner-close-btn]')
  bannerCloseBtn.addEventListener('click', () => {
    bannerElem.dataset.articleFooterBannerStatus = 'hide'
    bannerElem.dataset.articleFooterBannerDisplay = 'false'
    sessionStorage.setItem('articleFooterBanner', new Date());
    if (isMobile) { initNewPagetopBtn() }
  });

  bannerElem.dataset.articleFooterBannerDisplay = 'true'
  if (isMobile) { replaceDefaultPageTopBtn() }

  const bannerVisibleHeightPosition = document.querySelector('[data-article-footer-banner-visible-position]').dataset.articleFooterBannerVisiblePosition
  const currentPosition = window.scrollY
  if (currentPosition > bannerVisibleHeightPosition) {
    bannerElem.dataset.articleFooterBannerStatus = 'visible'
  }

  window.addEventListener('scroll', () => {
    const currentScrollPosition = window.scrollY
    const atlasFooterClassName = isMobile ? 'atlas-sp-footer' : 'atlas-footer'
    const atlasFooterElem = document.getElementsByClassName(atlasFooterClassName)[0]
    const atlasFooterPositionY = atlasFooterElem.getBoundingClientRect().top + window.pageYOffset
    const bannerBottomPosition = currentScrollPosition - bannerElem.offsetHeight + window.outerHeight

    if(bannerBottomPosition > atlasFooterPositionY) {
      bannerElem.dataset.articleFooterBannerStatus = 'hide'
    } else if(currentScrollPosition > bannerVisibleHeightPosition) {
      bannerElem.dataset.articleFooterBannerStatus = 'visible'
    }
  })
});
