// NOTE: ABテスト用のモジュール
//       どの会員登録リンクからCVしたかわかるように各リンクにパラメータを付与している
window.addEventListener('load', () => {
  const isVwoTestPage = document.getElementsByTagName('body')[0].dataset.isVwoTestPage === "true"
  if(!isVwoTestPage) return;

  const vwoPatternName = document.getElementsByTagName('body')[0].dataset.vwoPageName
  const registerUrl = 'https://www.m3.com/registration/form.jsp'
  const addQuery = (link, positionName) => {
    link.href = link.href + `&vwo_pattern=${vwoPatternName}&link_position=${positionName}`
  }

  const headerLinks = document.querySelectorAll('.float-header a')
  const headerRegisterLinks = [...headerLinks].filter((link) => link.href.startsWith(registerUrl))
  headerRegisterLinks.forEach((link) => {
    addQuery(link, 'header')
  })

  const rhsLinks = document.querySelectorAll('#atlas-rhs a')
  const rhsRegisterLinks = [...rhsLinks].filter((link) => link.href.startsWith(registerUrl))
  rhsRegisterLinks.forEach((link) => {
    addQuery(link, 'rhs')
  })

  const allLinks = document.querySelectorAll('a')
  const otherRegisterLinks = [...allLinks].filter((link) => {
    if(link.href.startsWith(registerUrl) && !headerRegisterLinks.includes(link) && !rhsRegisterLinks.includes(link)) {
      return link
    }
  })
  otherRegisterLinks.forEach((link) => {
    addQuery(link, 'others')
  })
})
